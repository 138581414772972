// COLORS
export const RAINBOW_HEX_ARRAY = [
  "#FF0000",
  "#FF9A00",
  "#D0DE21",
  "#4FDC4A",
  "#3FDAF8",
  "#2FC9E2",
  "#1C7FEE",
  "#5F15F2",
  "#BA0CF8",
  "#FB07D9",
  "#FF0000",
];
export const RAINBOW_GRADIENT_STRING =
  "linear-gradient(90deg, rgba(255, 0, 0, 0.25) 0%, rgba(255, 154, 0, 0.25) 10%, rgba(208, 222, 33, 0.25) 20%, rgba(79, 220, 74, 0.25) 30%, rgba(63, 218, 216, 0.25) 40%, rgba(47, 201, 226, 0.25) 50%, rgba(28, 127, 238, 0.25) 60%, rgba(95, 21, 242, 0.25) 70%, rgba(186, 12, 248, 0.25) 80%, rgba(251, 7, 217, 0.25) 90%, rgba(255, 0, 0, 0.25) 100%)";
