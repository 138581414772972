import "./styles.css";

import { Col, Row } from "react-bootstrap";

import { RAINBOW_GRADIENT_STRING } from "../../constants";
import React from "react";

const Roadmap = () => {
  return (
    <div className="Roadmap">
      <div style={{ background: RAINBOW_GRADIENT_STRING }}>
        <h1 className="text-center">Roadmap</h1>
        <Row>
          <Col>
            <h3>Phase 1</h3>
            <ul>
              <li>Release on pump.fun</li>
              <li>Reach 100 holders</li>
              <li>Create Telegram Group</li>
              <li>Set up page on X</li>
              <li>$500 Marketing</li>
            </ul>
          </Col>
          <Col>
            <h3>Phase 2</h3>
            <ul>
              <li>Get to Raydium</li>
              <li>Get to 1000 holders</li>
              <li>Random 5 SOL holder giveaway</li>
              <li>$2000 Marketing</li>
            </ul>
          </Col>
          <Col>
            <h3>Phase 3</h3>
            <ul>
              <li>Listing on DEX Screener</li>
              <li>Get to 5000 holders</li>
              <li>Random 5 SOL holder giveaway</li>
              <li>$3000 more in Marketing</li>
              <li>Get to the moon 🚀</li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Roadmap;
