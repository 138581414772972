import "./styles.css";

import { RAINBOW_GRADIENT_STRING } from "../../constants";
import React from "react";

const About = () => {
  return (
    <div className="About">
      <div style={{ background: RAINBOW_GRADIENT_STRING }}>
        <h1 className="text-center">About</h1>
        <div className="text-center">
          Slamming his balls hard as fuck on your dinner table.
        </div>
      </div>
    </div>
  );
};

export default About;
