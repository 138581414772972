import "./styles.css";

import { Button } from "react-bootstrap";
import React from "react";

const BuyNowButton = (size) => {
  return (
    <Button variant="warning" className="btn-lg fs-3 px-5 fw-bold">
      BUY NOW
    </Button>
  );
};

export default BuyNowButton;
