import "./styles.css";

import { Col, Row } from "react-bootstrap";

import BuyNowButton from "../BuyNow";
import Logo from "../Logo";
import React from "react";

const Header = () => {
  return (
    <Row className="Header d-flex w-100 justify-content-between align-items-center">
      <Col className="d-flex w-100 justify-content-between align-items-center mt-5">
        <Logo />
        <h1>RAIMBO</h1>
        <BuyNowButton />
      </Col>
    </Row>
  );
};

export default Header;
