import "./App.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import About from "./components/About";
import BannerPhoto from "./components/BannerPhoto";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Roadmap from "./components/Roadmap";
import Tokenomics from "./components/Tokenomics";

function App() {
  return (
    <Container>
      <Header />
      <BannerPhoto />
      {/* <About /> */}
      {/* <Tokenomics /> */}
      <Roadmap />
    </Container>
  );
}

export default App;
